import { Header } from "../header/Header"
import { Footer } from "../footer/Footer"
import { Questions } from "../pricing//elements/questions/Questions"

import faq from "./faq.module.scss"

export const FAQ = () => {
  return (
    <main className={faq.faqing}>
      <Header />

      <div className="grid2">

        <header className={faq.title}>
          <h1>Frequenty Asked Questions</h1>
        </header>
        <section className={faq.questions}>
          <Questions toggle="el1" tm={false}
            title="I have a large client portfolio, can I import the data and appointments that I have in another system?">
            <p>
              That's not a problem, yes you can! Our system can handle your data
              and appointments already created. You can import this information
              into PieBooking.
            </p>
          </Questions>
          <Questions toggle="el2" tm={false}
            title="Can I include my logo?">
            <p>Yes, you can upload your logo to promote your brand.
            </p>
          </Questions>
          <Questions toggle="el3" tm={false}
            title="Can I offer suggestions to make Pie better?">
            <p>Definitely! Please do so! We created Piebooking with barber
            feedback and direction at all times. This is how we are different
            from other booking apps out there. We welcome your suggestions to
            make your booking app and your client experience better. Thank you
            for taking the time to leave your feedback! Send your suggestions to
            {" "}<a href="mailto:support@piebooking.com">support@piebooking.com</a>.
            </p>
          </Questions>
          <Questions toggle="el4" tm={false}
            title="I still have some questions, how can I contact you?">
            <p>Please send an email to {" "}
            <a href="mailto:support@piebooking.com">support@piebooking.com</a>
            {" "} and we will answer your questions as soon as possible. Our
            support team works Monday through Friday from 9am to 5pm EST.
            </p>
          </Questions>
          <Questions toggle="el5" tm={false}
            title="Can you explain your pricing?">
            <p>Of course! You pay for what you need!  Our pricing will be
            announced soon.</p>
          </Questions>
          <Questions toggle="el6" tm={false}
            title="Can I get a refund for my subscription?">
            <p>No, we do not offer refunds for subscriptions. However, we will
            not charge your debit or credit card the following month after you
            notify us to cancel your subscription.
            </p>
          </Questions>
          <Questions toggle="el7" tm={false}
            title="Can I cancel my subscription at any time?">
            <p>Yes you can! You won’t be charged the following month after you
            notify us of your cancellation. We are going to cancel your
            subscription right away, but depending on your card billing cycle,
            you may be charged one last time. Send us an email asking for
            subscription cancellation to {" "}
            <a href="mailto:support@piebooking.com">support@piebooking.com</a>
            {" "} and we’ll take care of it.
            </p>
          </Questions>
          <Questions toggle="el8" tm={false}
            title="Do I need a credit or debit card to subscribe to Pie?">
            <p>Yes, we need a card to charge your subscription every month.
            </p>
          </Questions>
          <Questions toggle="el9" tm={false}
            title="Do I need to be a paid user to use PieBooking?">
            <p>Yes, you need to pay a monthly subscription fee.  Your clients
            will not pay anything, beside your services, of course!  Pie is free
            for them!
            </p>
          </Questions>
        </section>
      </div>

      <Footer />
      <div className={faq.wave}></div>
      <div className={faq.wave2}></div>
    </main>
  )
}
