import { useState } from "react"
import question from "./questions.module.scss"

export const Questions = ({ title, toggle, children, tm=true }) => {
  let [button, setButton] = useState(false)
  const toggleQuestion = () => {
    let toggleQuestion = document.querySelector(
      `.${toggle}`
    )
    toggleQuestion
      .querySelector("p")
      .classList.toggle("show-question")

    if (!button) {
      toggleQuestion.querySelector(
        "button"
      ).innerHTML = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#06BD69"/></svg>`
      setButton(true)
    } else {
      toggleQuestion.querySelector(
        "button"
      ).innerHTML = `<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 3.00007L15.885 0.885071L9 7.75507L2.115 0.88507L1.8149e-06 3.00007L9 12.0001L18 3.00007Z" fill="#06BD69"/></svg>`
      setButton(false)
    }

    // toggleQuestion.nextElementSibling && toggleQuestion.nextSibling.querySelector('p').classList.remove('show-question')
    // toggleQuestion.previousElementSibling && toggleQuestion.previousSibling.querySelector('p').classList.remove('show-question')
  }

  return (
    <article
      className={`${question.question} ${toggle}`}
      onClick={toggleQuestion}
    >
      <h3>
        <button>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 3.00007L15.885 0.885071L9 7.75507L2.115 0.88507L1.8149e-06 3.00007L9 12.0001L18 3.00007Z"
              fill="#06BD69"
            />
          </svg>
        </button>
        <strong>{title}</strong> {tm ?<sup>&trade;</sup> :""}
      </h3>
      {children}
    </article>
  )
}
