import footer from "./footer.module.scss"

export const Footer = () => {
  return (
    <footer className={footer.footer}>
      <div className={footer.logo}>
        <svg
          width="241"
          height="48"
          viewBox="0 0 241 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M24.9133 15.1858H16.3844V25.2089C16.3345 25.2089 16.3095 25.2089 16.2597 25.2089C13.8157 25.2089 11.7957 24.3778 10.075 22.6401C8.35422 20.9025 7.53125 18.8626 7.53125 16.3946C7.53125 13.9518 8.37915 11.9119 10.0999 10.1742C11.8206 8.43657 13.8406 7.58032 16.2846 7.58032C18.7286 7.58032 20.7486 8.43657 22.4693 10.1742C23.8659 11.6349 24.6639 13.2718 24.9133 15.1858Z"
              fill="#ffffff"
            />
            <path
              d="M41.3728 31.8573C41.3728 34.3253 40.5499 36.34 38.8291 38.0776C37.1084 39.8153 35.0884 40.6464 32.6444 40.6464C30.2005 40.6464 28.1805 39.8153 26.4597 38.0776C24.739 36.34 23.916 34.3253 23.916 31.8573C23.916 31.5299 23.941 31.2025 23.9659 30.8751C25.3624 30.1196 26.6592 29.1374 27.7815 28.0042C29.1531 26.6191 30.3002 24.957 31.1232 23.1437C31.622 23.0682 32.1457 23.0178 32.6694 23.0178C35.1133 23.0178 37.1333 23.8741 38.8541 25.6117C40.5249 27.3746 41.3728 29.4145 41.3728 31.8573Z"
              fill="#ffffff"
            />
            <path
              d="M48.9043 31.6054C48.9043 36.1385 47.0838 40.2434 44.1411 43.2151C41.1983 46.1868 37.1334 48.0252 32.6445 48.0252C24.0657 48.0252 17.058 41.3263 16.4346 32.8394C19.1528 32.8143 21.7215 32.1091 23.941 30.9003C23.9161 31.2277 23.8911 31.5299 23.8911 31.8825C23.8911 34.3505 24.739 36.3652 26.4348 38.1028C28.1556 39.8405 30.1756 40.6716 32.6195 40.6716C35.0635 40.6716 37.0835 39.8405 38.8043 38.1028C40.525 36.3652 41.348 34.3505 41.348 31.8825C41.348 29.4397 40.525 27.3998 38.7793 25.6621C37.0586 23.9244 35.0386 23.0682 32.5946 23.0682C32.046 23.0682 31.5472 23.1186 31.0484 23.1941C31.9712 21.1291 32.4699 18.8625 32.4699 16.4701C32.4699 16.042 32.445 15.639 32.42 15.2361H32.5946C37.0835 15.2361 41.1485 17.0745 44.0912 20.0462C47.0838 22.9675 48.9043 27.0724 48.9043 31.6054Z"
              fill="#ffffff"
            />
            <path
              d="M32.4697 15.1857H24.9134C24.664 13.2718 23.866 11.6348 22.4196 10.1742C20.6988 8.43652 18.6788 7.58027 16.2349 7.58027C13.7909 7.58027 11.7709 8.43652 10.0501 10.1742C8.3294 11.9119 7.4815 13.9517 7.4815 16.3945C7.4815 18.8625 8.3294 20.9024 10.0252 22.6149C11.746 24.3526 13.766 25.1836 16.2099 25.1836C16.2598 25.1836 16.2847 25.1836 16.3346 25.1836V31.5803C16.3346 32.0084 16.3595 32.4113 16.3845 32.8143C16.3346 32.8143 16.2598 32.8143 16.2099 32.8143H0V16.4197C0 7.35362 7.28199 0 16.2598 0C20.7487 0 24.8136 1.83841 27.7564 4.81007C30.4248 7.50472 32.1704 11.1312 32.4697 15.1857Z"
              fill="white"
            />
            <path
              d="M126.288 16.1426C124.467 14.3042 121.948 13.1458 119.155 13.1458H109.055V23.3451C109.055 28.9611 113.569 33.5445 119.155 33.5445C121.948 33.5445 124.467 32.4112 126.288 30.5476C128.108 28.7092 129.255 26.1657 129.255 23.3451C129.255 20.5246 128.108 18.0062 126.288 16.1426ZM122.996 27.3745C121.923 28.4574 120.676 28.9611 119.155 28.9611C117.634 28.9611 116.387 28.4322 115.315 27.3745C114.242 26.2916 113.719 25.0324 113.719 23.5214C113.719 22.0104 114.242 20.726 115.315 19.6431C116.387 18.5602 117.634 18.0314 119.155 18.0314C120.676 18.0314 121.923 18.5602 122.996 19.6431C124.068 20.726 124.592 21.9852 124.592 23.5214C124.567 25.0576 124.068 26.3168 122.996 27.3745Z"
              fill="#ffffff"
            />
            <path
              d="M83.8675 14.7322H79.2788V33.2673H83.8675V14.7322Z"
              fill="white"
            />
            <path
              d="M101.848 26.3672C101.399 26.896 101.125 27.2486 101 27.3745C99.9278 28.4574 98.6559 28.9611 97.1596 28.9611C96.0374 28.9611 95.3391 28.81 94.8902 28.6337L103.519 19.9202L105.639 17.7796L105.29 17.3766C104.766 16.7722 104.442 16.4196 104.317 16.2937C104.043 16.0167 103.743 15.7397 103.444 15.4879C101.648 14.0272 99.5287 13.2969 97.1596 13.2969C94.3914 13.2969 91.9974 14.3042 90.0272 16.2937C88.0571 18.2832 87.0596 20.7009 87.0596 23.5214C87.0596 26.342 88.0571 28.7596 90.0272 30.7491C91.9974 32.7386 94.3914 33.746 97.1596 33.746C99.9278 33.746 102.347 32.7386 104.317 30.7491C104.716 30.3462 105.339 29.5403 106.212 28.3315L106.636 27.7271L102.097 26.0902L101.848 26.3672ZM97.1596 18.0314C97.9327 18.0314 98.4315 18.0818 98.7806 18.1321L91.8727 25.1332C91.7979 24.7806 91.748 24.2518 91.748 23.4962C91.748 21.96 92.2717 20.7009 93.344 19.618C94.3914 18.5603 95.6633 18.0314 97.1596 18.0314Z"
              fill="#ffffff"
            />
            <path
              d="M148.907 16.2937C146.937 14.3042 144.543 13.2969 141.774 13.2969C139.006 13.2969 136.612 14.3042 134.642 16.2937C132.672 18.2832 131.674 20.726 131.674 23.5214C131.674 26.3168 132.672 28.7596 134.642 30.7491C136.612 32.7386 139.006 33.746 141.774 33.746C144.543 33.746 146.962 32.7386 148.907 30.7491C150.877 28.7596 151.849 26.342 151.849 23.5466C151.849 20.7512 150.852 18.2832 148.907 16.2937ZM145.59 27.3745C144.518 28.4574 143.271 28.9611 141.749 28.9611C140.228 28.9611 138.981 28.4322 137.909 27.3745C136.837 26.2916 136.313 25.0324 136.313 23.5214C136.313 22.0104 136.837 20.726 137.909 19.6431C138.981 18.5603 140.228 18.0314 141.749 18.0314C143.271 18.0314 144.518 18.5603 145.59 19.6431C146.662 20.726 147.186 21.9852 147.186 23.5214C147.186 25.0576 146.662 26.3168 145.59 27.3745Z"
              fill="#ffffff"
            />
            <path
              d="M189.207 14.7323H183.297L181.451 17.024V8.20972H176.838V33.2674H181.451V25.9642L185.392 33.2674H190.828L184.045 21.1793L189.207 14.7323Z"
              fill="#ffffff"
            />
            <path
              d="M197.536 14.7322H192.948V33.2673H197.536V14.7322Z"
              fill="#ffffff"
            />
            <path
              d="M210.155 13.7754C208.534 13.7754 207.237 14.2791 206.34 15.2864V14.7324H201.726V33.2675H206.34V20.0965C206.439 19.9454 206.614 19.6935 206.963 19.3158C207.587 18.7114 208.31 18.4344 209.158 18.4344C210.031 18.4344 210.754 18.7366 211.352 19.341C211.976 19.9454 212.275 20.6757 212.275 21.5068V33.2675H216.864V21.3809C216.839 19.2402 216.215 17.427 215.043 15.9916C213.821 14.5309 212.175 13.7754 210.155 13.7754Z"
              fill="#ffffff"
            />
            <path
              d="M73.0444 16.3189C71.2239 14.4805 68.7051 13.322 65.912 13.322C60.3258 13.322 55.812 17.8803 55.812 23.5214V33.7208H65.912C68.7051 33.7208 71.2239 32.5875 73.0444 30.7239C74.8649 28.8855 76.0121 26.342 76.0121 23.5214C76.0121 20.7008 74.8649 18.1573 73.0444 16.3189ZM69.7525 27.3745C68.6802 28.4574 67.4333 28.9611 65.912 28.9611C64.3908 28.9611 63.1439 28.4322 62.0715 27.3745C60.9992 26.2916 60.4755 25.0324 60.4755 23.5214C60.4755 22.0104 60.9992 20.726 62.0715 19.6431C63.1439 18.5602 64.3908 18.0314 65.912 18.0314C67.4333 18.0314 68.6802 18.5602 69.7525 19.6431C70.8249 20.726 71.3486 21.9852 71.3486 23.5214C71.3237 25.0576 70.7999 26.3168 69.7525 27.3745Z"
              fill="white"
            />
            <path
              d="M173.945 23.4962C173.945 20.7009 172.947 18.2832 170.977 16.2937C169.007 14.3042 166.613 13.2969 163.82 13.2969C161.052 13.2969 158.633 14.3042 156.662 16.2937C154.692 18.2832 153.695 20.7009 153.695 23.4962C153.695 26.2916 154.692 28.7344 156.662 30.6988C158.633 32.6883 161.027 33.6704 163.82 33.6704C166.588 33.6704 169.007 32.6631 170.977 30.6988C172.947 28.7093 173.945 26.2916 173.945 23.4962ZM158.433 23.4962C158.433 21.96 158.957 20.7009 160.004 19.618C161.077 18.5351 162.324 18.0062 163.82 18.0062C165.316 18.0062 166.563 18.5351 167.66 19.618C168.733 20.7009 169.256 21.96 169.256 23.4962C169.256 25.0324 168.733 26.2916 167.66 27.3745C166.588 28.4574 165.341 28.9863 163.82 28.9863C162.299 28.9863 161.052 28.4574 160.004 27.3745C158.957 26.2916 158.433 25.0073 158.433 23.4962Z"
              fill="#ffffff"
            />
            <path
              d="M230.405 13.322C227.612 13.322 225.093 14.4553 223.273 16.3189C221.452 18.1573 220.305 20.7008 220.305 23.5214C220.305 26.342 221.427 28.8855 223.273 30.7239C225.093 32.5623 227.612 33.7208 230.405 33.7208H233.872C234.37 33.7208 234.769 34.1237 234.769 34.6274C234.769 35.1311 234.37 35.534 233.872 35.534H232.849H220.33V40.1678H232.999C235.119 40.1426 236.914 39.513 238.336 38.3294C239.707 37.1709 240.455 35.6095 240.53 33.7208V23.5214C240.505 17.9054 235.991 13.322 230.405 13.322ZM230.405 28.9862C228.884 28.9862 227.637 28.4574 226.565 27.3997C225.492 26.3168 224.969 25.0576 224.969 23.5466C224.969 22.0356 225.492 20.7764 226.565 19.6683C227.637 18.5854 228.884 18.0565 230.405 18.0565C231.926 18.0565 233.173 18.5854 234.246 19.6683C235.318 20.7512 235.842 22.0104 235.842 23.5466C235.842 25.0828 235.318 26.342 234.246 27.3997C233.173 28.4574 231.926 28.9862 230.405 28.9862Z"
              fill="#ffffff"
            />
            <path
              d="M196.838 36.2139C196.389 35.7606 195.841 35.5591 195.217 35.5591C194.594 35.5591 194.07 35.7857 193.621 36.2139C193.172 36.6672 192.948 37.2212 192.948 37.8508C192.948 38.5056 193.172 39.0596 193.621 39.4877C194.07 39.941 194.619 40.1425 195.217 40.1425C195.841 40.1425 196.389 39.9159 196.838 39.4877C197.287 39.0344 197.511 38.4804 197.511 37.8508C197.536 37.2212 197.312 36.6672 196.838 36.2139Z"
              fill="white"
            />
            <path
              d="M60.4007 28.0796H55.812V40.1677H60.4007V28.0796Z"
              fill="white"
            />
            <path
              d="M196.838 11.7858C196.389 12.2391 195.841 12.4406 195.217 12.4406C194.594 12.4406 194.07 12.2139 193.621 11.7858C193.172 11.3325 192.948 10.7785 192.948 10.1489C192.948 9.5193 193.172 8.94007 193.621 8.51195C194.07 8.05865 194.619 7.85718 195.217 7.85718C195.841 7.85718 196.389 8.08383 196.838 8.51195C197.287 8.96526 197.511 9.5193 197.511 10.1489C197.536 10.7785 197.312 11.3325 196.838 11.7858Z"
              fill="white"
            />
            <path
              d="M83.1941 36.2139C82.7452 35.7606 82.1966 35.5591 81.5731 35.5591C80.9496 35.5591 80.4259 35.7857 79.9771 36.2139C79.5282 36.6672 79.3037 37.2212 79.3037 37.8508C79.3037 38.5056 79.5282 39.0596 79.9771 39.4877C80.4259 39.941 80.9746 40.1425 81.5731 40.1425C82.1966 40.1425 82.7452 39.9159 83.1941 39.4877C83.643 39.0344 83.8674 38.4804 83.8674 37.8508C83.8674 37.2212 83.643 36.6672 83.1941 36.2139Z"
              fill="#ffffff"
            />
            <path
              d="M83.1941 11.7858C82.7452 12.2391 82.1966 12.4406 81.5731 12.4406C80.9496 12.4406 80.4259 12.2139 79.9771 11.7858C79.5282 11.3325 79.3037 10.7785 79.3037 10.1489C79.3037 9.5193 79.5282 8.94007 79.9771 8.51195C80.4259 8.05865 80.9746 7.85718 81.5731 7.85718C82.1966 7.85718 82.7452 8.08383 83.1941 8.51195C83.643 8.96526 83.8674 9.5193 83.8674 10.1489C83.8674 10.7785 83.643 11.3325 83.1941 11.7858Z"
              fill="#ffffff"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="240.505"
                height="48"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        &trade;
      </div>
      <div className={footer.links}>
        <a href="https://piebooking.com/terms">
          Terms and Conditions
        </a>
        <a href="https://piebooking.com/privacy">
          Privacy Policy
        </a>
        <a href="/">Powered by Pie Services&trade;</a>
      </div>
    </footer>
  )
}
