import { Button } from "../elements/buttons/Button"
import create from "./create.module.scss"
import { Link } from "react-router-dom"

export const Create = () => {
  return (
    <section className={create.area} id="how-create">
      <div className="grid">
        <h2 className="title">
          How do I create my website or app?
        </h2>

        <div className={create.information}>
          <div className={create.list}>
            <div className={create.box}>
              <div
                className={`${create.number} ${create.pointer1}`}
              >
                1
              </div>
              <span>
                <b>
                  Support the influencer that brought you
                  here
                </b>
                Our influencers are part of the family and
                we give them a piece of the Pie. Choose who
                brought you here!
              </span>
            </div>

            <div className={create.box}>
              <div
                className={`${create.number} ${create.pointer2}`}
              >
                2
              </div>
              <span>
                <b>Choose your app and website style</b>
                Choose from different options that best
                represents you and bring your logo for a
                unique look and style!
              </span>
            </div>

            <div className={create.box}>
              <div
                className={`${create.number} ${create.pointer3}`}
              >
                3
              </div>
              <span>
                <b>Choose your domain</b>
                Pie wants to help you elevate your brand,
                not your booking app. Send your clients to
                your very own website!
              </span>
            </div>

            {/* <div className={create.box} >
                        <div className={`${create.number} ${create.pointer4}`}>4</div>
                            <span>
                                <b>Build your custom plan</b>
                                Pay only for what you need. Choose the number of shops and barbers you have.
                            </span>
                        </div> */}

            <div className={create.box}>
              <div
                className={`${create.number} ${create.pointer5}`}
              >
                4
              </div>
              <span>
                <b>Publish your app and website – Done!</b>
                We take care of everything for you! You can
                now start promoting yourself and managing
                your business.
              </span>
            </div>
          </div>

          <div className={create.image}>
            <img
              className={create.bgFeatures}
              src="/bg-features.svg"
              alt=""
            />
            <img
              className="reveal"
              src="/smartmockups.jpg"
              alt="How do I create my website or app?"
            />
            <Button
              typeButton="fullGreen"
              signup_link={true}
            >
              <Link to="/pricing">
                Click here to Start Promoting Yourself
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
