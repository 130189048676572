import { Button } from "../elements/buttons/Button"
import customize from "./customizePlans.module.scss"
import { Link } from "react-router-dom"

export const CustomizePlans = () => {
  return (
    <section className={customize.area}>
      <svg
        width="48"
        height="18"
        viewBox="0 0 48 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1562 17.4363C19.1905 17.5208 22.2281 15.355 24 13.0769C27.5077 17.704 32.5691 17.1715 35.1655 17.4363C42.1968 17.3799 48 11.6144 48 4.58315V1.96753C48 1.17994 47.3813 0.561279 46.5938 0.561279C45.8062 0.561279 45.1875 1.17994 45.1875 1.96753C45.1875 4.302 43.3032 6.18628 40.9688 6.18628H40.125C39.1687 6.18628 38.2407 5.84878 37.4812 5.25806L33.4218 2.07994C32.2124 1.09565 30.6656 0.561279 29.0906 0.561279C26.5875 0.561279 24.9563 1.82691 24 2.78306C23.0437 1.79878 21.4687 0.561279 18.9094 0.561279C17.3344 0.561279 15.7875 1.09565 14.5782 2.07994L10.6125 5.25806C9.85303 5.84878 8.92509 6.18628 7.96875 6.18628H7.125C4.79053 6.18628 2.8125 4.302 2.8125 1.96753C2.8125 1.17994 2.19384 0.561279 1.40625 0.561279C0.618656 0.561279 0 1.17994 0 1.96753V4.10503C0 12.7113 5.60634 17.3239 14.1562 17.4363Z"
          fill="#212121"
        />
      </svg>

      <h2 className="title">Let’s go!</h2>
      {/* <p>
          Choose the best plan for you and your business
        </p> */}
      <Link to="/pricing">
        <Button typeButton="fullGreen">
          Click here to start promoting yourself
        </Button>
      </Link>
    </section>
  )
}
