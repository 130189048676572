import { useEffect } from "react"
import influencer from "./influencer.module.scss"
import { AvatarInfluencer } from "../elements/influencer/AvatarInfluencer"

export const Influencer = ({
  title,
  subtitle,
  mlocs = null,
  mpros = null,
}) => {
  useEffect(() => {
    let cards = document.querySelectorAll(
      `.${influencer.areaPictures} figure`
    )
    for (let i = 0; i < cards.length; i++) {
      let target =
        Math.floor(Math.random() * cards.length - 1) + 1
      let target2 =
        Math.floor(Math.random() * cards.length - 1) + 1
      cards[target].before(cards[target2])
    }
  }, [])

  return (
    <section className={`grid2 ${influencer.area}`}>
      <h2 className="title">{title}</h2>
      <p className="subtitle">{subtitle}</p>

      <section className={influencer.areaPictures}>
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/mike-canales.png"
          title="Mike Canales"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/craft-blendz.png"
          title="S. Craft Blendz"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/seancutshair.png"
          title="Seancutshair"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/beam.png"
          title="Beam"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/bonezdagoat.png"
          title="BonezDaGoat"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/matt-giftedhands.png"
          title="Matt Giftedhands"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/barber.josh.o.p.png"
          title="Barber.josh.o.p"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/rachel-romero.png"
          title="Rachel Romero"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/chris-minnieweater.png"
          title="Chris Minnieweather"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/zack-the-barber.png"
          title="Zack The Barber"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/chris-gonzalez.png"
          title="Chris Gonzalez"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/david-falla.png"
          title="David Falla"
        />
        <AvatarInfluencer
          mlocs={mlocs}
          mpros={mpros}
          image="/influencers/bossiocuts.png"
          title="Bossiocuts"
        />
      </section>
    </section>
  )
}
