import information from './information.module.scss';

export const Information1 = ({img, title, children }) => {
    return(
        <section className={`${information.areaInformation} reveal`}>
            <div className={`${information.image1}`}>
                <img src="/bg-features2.svg" />
                <figure className={`${information.figure}`}>
                    <img src={img} alt={title}/>
                </figure>
            </div>
            <div className={information.content1}>
                <h3 className="title">{title}</h3>
                {children}
            </div>
        </section>
    )
}

export const Information2 = ({img, title, children }) => {
    return(
        <section className={`${information.areaInformation} reveal`}>
            <div className={information.content2}>
                <h3 className="title">{title}</h3>
                {children}
            </div>
            <div className={`${information.image2}`}>
                <img src="/bg-features.svg" />
                <figure className={`${information.figure}`}>
                    <img src={img} alt={title}/>
                </figure>
            </div>
        </section>
    )
}