import features from './features.module.scss';

export const Feature = ({title, content, image, coming}) => {
    return(
        <article className={features.content}>
            <div>
                <div className={features.text}>
                    <h2>{ title }</h2>
                    <div className={features.comming}>
                        { coming && (<em>Coming soon</em>)  }
                    </div>
                    
                    <div className={features.image}>
                        {/* <img src={ image } alt=""/> */}
                        <video id="mv" autoPlay muted loop playsInline controls={false} disablePictureInPicture controlsList="nodownload" >
                            <source src={image} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                        </video>
                    </div>

                    <p>{ content }</p>
                </div>
            </div>
        </article>
    )
}   