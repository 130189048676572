import { Create } from '../create/Create';
import { CustomizePlans } from '../customizePlans/customizePlans';
import { Discover } from '../discover/Discover';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { Influencer } from '../influencer/Influencer';
import { Information1 } from '../informations/Information';
import { Information2 } from '../informations/Information';
import { Banner } from './banner/Banner';
import ScrollReveal from 'scrollreveal';
import home from './home.module.scss';
import { useEffect } from 'react';
import { Button } from '../elements/buttons/Button';
import { Link } from 'react-router-dom';

export const Home = () => {

    useEffect(
        () => {
            window.scrollTo(0, 0);
            ScrollReveal().reveal('.reveal', {delay: 200});
        }, []
    )

    return(
        <main className={home.home}>
            <Header />
            <Banner />
            <Create />
            <Influencer
                title="Support your Influencer"
                subtitle="Support your influencer by choosing who brought you here"
            />

            <p className={home.paragraph}>Click below if you came here without an influencer</p>

            <div className="grid">
                <Button typeButton="ghostGreen" signup_link={true}>
                    Continue without an influencer
                </Button>
            </div>
            
            <Information1 
                img='/your-own-custom-lp.png'
                title="Your own custom website and app made simple"
            >
            <p>Creating your own custom app can be super complex and costly! <strong>Pie gives you the platform to create your own website and app</strong> published on the Apple Store and Google Play!</p>
            </Information1>
            <Information2 
                img='/you-have-to-do.png'
                title="All you have to do is launch our easy-to-use app and website builder"
            >
            <p><strong>Upload your logo and choose from available templates for a unique look</strong>. Customize colors, banners, and much more. Once you’re done, click publish for the ultimate online presence!</p>
            </Information2>
            <Information1 
                img='/you-are-in-control.png'
                title="You are in control of a user-friendly platform"
            >
            <p>Once you login, you’ll be greeted by an intuitive interface. <strong>This All-in-one platform features tools that will help you manage your clients, scheduling, team, POS, and so much more!</strong></p> 
            </Information1>

            <Discover />
            <CustomizePlans />
            <Footer />  
            <div className={home.wave}></div>        
        </main>
    )
}
