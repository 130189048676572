import { Button } from '../elements/buttons/Button';
import discover from './discover.module.scss';
import { ElementDiscover } from './../elements/discover/ElementDiscover';
import { Link } from 'react-router-dom';

export const Discover = () => {
    return(
        <section className={`${discover.area} grid`}>
            <div className={discover.info}>
                <h2 className="title">Discover all the features of your new barber shop management platform</h2>
            </div>
            <div className={discover.discover}>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/scheduler">
                    <ElementDiscover image="/discover-icons/scheduler.svg" title="Scheduler" />
                </Link>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/client-management">
                    <ElementDiscover image="/discover-icons/client-management.svg" title="Client Management" />
                </Link>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/service-management">
                    <ElementDiscover image="/discover-icons/service-management.svg" title="Service Management" />
                </Link>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/team-management">
                    <ElementDiscover image="/discover-icons/team-management.svg" title="Team Management" />
                </Link>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/reports">
                    <ElementDiscover image="/discover-icons/reports.svg" title="Reports" />
                </Link>
                <Link className={`${discover.redirect} ${discover.elements}`} to="/features/point-of-sale">
                    <ElementDiscover image="/discover-icons/pos.svg" title="Point of Sale" />
                </Link>
            </div>
            <Link className={`${discover.redirect} ${discover.link}`} to="/features/scheduler">
                <Button typeButton="fullGreen">
                    Learn more
                </Button>
            </Link>

        </section>
    )
}