import features from './features.module.scss';
import { CustomizePlans } from '../customizePlans/customizePlans';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Feature } from './Feature';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';

export const Features = () => {
    let name = useRouteMatch();
    useEffect(()=>  {
        window.scrollTo(0, 0);
        let closeElements = document.querySelectorAll(`.${features.navigate} a i`);
        closeElements.forEach(el => {
            el.addEventListener('click', e => {
                window.location.href = 'features/'
            })
        })
    },[]);
    return(
        <main className={features.features}>
            <Header />
            <section className="grid">
                <h1>Our Features</h1>

                <section className={features.area}>
                    <ul className={features.navigate}>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/scheduler`}><b>close</b><i>arrow</i>Scheduler</NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route exact path="/features/scheduler">
                                        <Feature 
                                            title="Scheduler" 
                                            image="/features/schedule.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/client-management`}><b>close</b><i>arrow</i>Client Management</NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/client-management">
                                        <Feature 
                                        title="Client Management" 
                                        image="/features/client-management.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/location-management`}><b>close</b><i>arrow</i>Location Management</NavLink>    
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/location-management">
                                        <Feature 
                                            title="Location Management" 
                                            image="/features/location-management.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/team-management`}><b>close</b><i>arrow</i>Team Management</NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/team-management">
                                        <Feature 
                                            title="Team Management" 
                                            image="/features/team-management.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/service-management`}><b>close</b><i>arrow</i>Service Management</NavLink>    
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/service-management">
                                        <Feature 
                                            title="Service Management" 
                                            image="/features/service-management.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/app-builder`}><b>close</b><i>arrow</i>App Builder</NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/app-builder">
                                        <Feature 
                                            title="App Builder" 
                                            image="/features/app-builder.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/website-builder`}><b>close</b><i>arrow</i>Website Builder</NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/website-builder">
                                        <Feature 
                                            title="Website Builder" 
                                            image="/features/site-builder.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/reports`}><b>close</b><i>arrow</i>Reports <em>Coming soon</em> </NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/reports">
                                        <Feature
                                            // coming = {true}
                                            title="Reports" 
                                            image="/features/reports.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/point-of-sale`}><b>close</b><i>arrow</i>Point of Sale (PoS) <em>Coming soon</em> </NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/point-of-sale">
                                        <Feature 
                                            title="Point of Sale" 
                                            image="/features/point-of-sale.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/waiting-list`}><b>close</b><i>arrow</i>Waiting List <em>Coming soon</em></NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/waiting-list">
                                        <Feature 
                                            // coming = {true}
                                            title="Waiting List" 
                                            image="/features/waiting-list.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                        <li>
                            <NavLink activeClassName={features.activeFeature} to={`${name.url}/inventory-management`}><b>close</b><i>arrow</i>Inventory Management <em>Coming soon</em></NavLink>
                            <div className={features.navigateContent}>
                                <Switch>
                                    <Route path="/features/inventory-management">
                                        <Feature 
                                            // coming = {true}
                                            title="Inventory Management" 
                                            image="/features/inventory.mp4" />
                                    </Route>
                                </Switch>
                            </div>
                        </li>
                    </ul>

                    <div className={features.information}>
                        <Switch>
                            <Route exact path="/features/scheduler">
                                <Feature 
                                    title="Scheduler" 
                                    content="Piebooking scheduler makes managing your appointments feel seamless. PieBooking Is an all-in-one solution with a streamlined scheduler that is both powerful and intuitive."
                                    image="/features/schedule.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/client-management">
                                <Feature 
                                    title="Client Management" 
                                    content="It’s your clientele and each of them are unique. With PieBooking, each of your clients get their own customer profile. These profiles allow us to create internal and external features that will help provide an experience that puts the clients first."
                                    image="/features/client-management.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/location-management">
                                <Feature 
                                    title="Location Management" 
                                    content="One PieBooking account will help manage all of your locations. Scale your business by streamlining all layers of your enterprise with this all-in-one solution. Create unique pricing, teams, services, schedules, and compensation plans for each of your locations or make them all the same. You are in control."
                                    image="/features/location-management.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/team-management">
                                <Feature 
                                    title="Team Management" 
                                    content="Your team and its members are vital to the success of your business. PieBooking gives you the power to manage team member profiles and customize each individual team member preferences to include service times and prices. You can even manage multiple locations for each individual team member!"
                                    image="/features/team-management.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/service-management">
                                <Feature 
                                    title="Service Management" 
                                    content="Your service management system comes with a robust amount of options! Set default services or customize services and service times to specific locations or individual team members."
                                    image="/features/service-management.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/app-builder">
                                <Feature 
                                    title="App Builder" 
                                    content={[
                                        "Promote your booking app, not another business’ booking app! Simply customize ",
                                        <strong>your own app with your logo</strong>,
                                        " on our app builder when you login to Pie Booking. We will publish It to the App Store or Google Play for your clients to download."
                                    ]}
                                    image="/features/app-builder.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/website-builder">
                                <Feature 
                                    title="Website Builder" 
                                    content="Use Pie Booking to build your own web presence with our website builder. Match the look of your app or customize your website using one of our templates."
                                    image="/features/site-builder.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/reports">
                                <Feature 
                                    coming = {true}
                                    title="Reports" 
                                    content="We offer reports to help you manage your business and payout your entire team with the click of a button. The payout report allows you to know exactly how much to pay each professional in your team on a weekly, bi-weekly or monthly basis. You decide!"
                                    image="/features/reports.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/point-of-sale">
                                <Feature 
                                    coming = {true}
                                    title="Point of Sale (PoS)" 
                                    content="With our integrated POS, you can charge your clients for your service directly from your app or website. You don’t need to wait for your appointment, you can set up pre-payment for your appointments. Fast and secure."
                                    image="/features/point-of-sale.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/waiting-list">
                                <Feature 
                                    coming = {true}
                                    title="Waiting List" 
                                    content="We are creating a unique approach for engaging walk-in customers.  Look for more information about this feature soon."
                                    image="/features/waiting-list.mp4" />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route path="/features/inventory-management">
                                <Feature 
                                    coming = {true}
                                    title="Inventory Management" 
                                    content="Spend time with your clients and not managing inventory. Easily add new products or update your inventory with the click of a button. Don’t sweat, you won’t run out of products anymore, just set up reminders when your stock is getting low."
                                    image="/features/inventory.mp4" />
                            </Route>
                        </Switch>
                    </div>

                </section>

            </section>
            <CustomizePlans />
            <Footer />
            <div className={features.wave}></div>
        </main>
    )
}