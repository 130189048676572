import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import signup from './signup.module.scss';

export const Signup = () => {
    return(
        <main>
            <Header />

                <div className={signup.visible_text}>

                <h1>You're almost there!</h1>

                <hr />

                <p>We're working around the clock to launch Pie
                Booking<sup>&trade;</sup>.</p>

                <p>Sign up for updates below.</p>

                <iframe src="https://app2.simpletexting.com/join/joinWebForm?webFormId=620ff35d66eba639b0d3614c&c=USA" width="470" height="410" scrolling="no"></iframe>

                </div>

            <Footer />
        </main>
    )

}
