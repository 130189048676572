import { Influencer } from '../../influencer/Influencer';
import modal from './modal.module.scss';

export const Modal = ({showClass, close, mlocs=null, mpros=null}) => {
    var link = window.location.protocol + '//';
    if (window.location.host.startsWith('lp.'))
        link += window.location.host.slice(3);
    else
        link += window.location.host;
    link = link.replace(':3000', ':5000');
    link += `/signup?referrer=&mlocs=${mlocs}&mpros=${mpros}`;
    link = "/signup";
    return(
        <section className={`${modal.modal} ${showClass}`}>
            <div className={modal.area}>
                <img className={modal.closed} onClick={close}  src="/closed-questions.svg" title="Close" />
                <Influencer mlocs={mlocs} mpros={mpros}
                    title="Choose the influencer that brought you here"
                />
                <a className={modal.lnkWithout} href={link}>Continue without an influencer</a>
            </div>
        </section>
    )
}
