import { Button } from "../../elements/buttons/Button"
import banner from "./banner.module.scss"
import { Link } from "react-router-dom"
import { useEffect } from "react"

import enableInlineVideo from "iphone-inline-video"

export const Banner = () => {
  useEffect(() => {
    var hideOrig = true

    var drawFrame = function (v, c) {
      if (v.paused || v.ended) return false
      var w = v.videoWidth
      var h = v.videoHeight
      c.canvas.width = w
      c.canvas.height = h
      c.drawImage(v, 0, 0, w, h)
      v.timeout = setTimeout(drawFrame, 30, v, c)
    }

    document
      .querySelectorAll("[data-replace-video-with-canvas]")
      .forEach(function (v) {
        var canvas = document.createElement("canvas")
        var ctx = canvas.getContext("2d")
        v.parentNode.appendChild(canvas)
        if (hideOrig) v.classList.add("shy")

        drawFrame(v, ctx)

        v.addEventListener("playing", function () {
          if (typeof v.timeout === "number")
            clearTimeout(v.timeout)
          drawFrame(this, ctx)
        })
      })

    const mv = document.getElementById("mv")
    mv.disablePictureInPicture = true

    const video = document.querySelector("video")
    enableInlineVideo(video)

    enableInlineVideo(video, {
      iPad: true,
    })
  }, [])

  return (
    <div>

    {/*
    <section className={banner.area2}>
        <div className={banner.centerText}>
          <center>
          <h2>
            PieBooking is raising funds through a Regulation CF offering,
            allowing you to own a piece of the Pie!
          </h2>
          <a href="http://investinpiebooking.com">
            <Button typeButton="fullWhite">
              Learn How You Can Own A Piece Of Pie
            </Button>
          </a>
          </center>
        </div>
    </section>
    */}

    <section className={banner.area}>
      <div className="grid d-flex">
        <div className={banner.box}>
          <h1>
            Stop promoting your booking app and start{" "}
            <strong>promoting yourself!</strong>
            <sup>&trade;</sup>
          </h1>
          <p>
            PieBooking is a platform that allows you to
            elevate your brand, not your booking app, by
            empowering you to{" "}
            <strong>
              build your own mobile app and website
            </strong>
            .
          </p>
          <Button typeButton="fullWhite" signup_link={true}>
            <Link to="/pricing">
              Click here to Start Promoting Yourself
            </Link>
          </Button>
        </div>
        <div className={banner.motion}>
          <img
            src="/01_piebooking_base_imagem.png"
            alt=""
          />
          <video
            id="mv"
            autoPlay
            muted
            loop
            playsInline
            controls={false}
            disablePictureInPicture
            controlsList="nodownload"
            poster="/01_piebooking_base_imagem.png"
            data-replace-video-with-canvas
          >
            <source
              src="/02_piebooking_base_video.webm"
              type='video/webm; codecs="vp8, vorbis"'
            />
            <source
              src="/02_piebooking_base_video.mp4"
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
      </div>
      <a href="#how-create" className={banner.chevron}>
        <svg
          width="32"
          height="48"
          viewBox="0 0 32 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.4102 27.41L24.5902 24.59L15.4102 33.75L6.23016 24.59L3.41016 27.41L15.4102 39.41L27.4102 27.41Z"
            fill="white"
          />
          <path
            d="M27.4102 11.41L24.5902 8.58997L15.4102 17.75L6.23016 8.58996L3.41016 11.41L15.4102 23.41L27.4102 11.41Z"
            fill="white"
          />
        </svg>
      </a>
    </section>
    </div>
  )
}
