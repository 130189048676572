import avatarInfluencer from './avatarInfluencer.module.scss';

export const AvatarInfluencer = ({image, title, mlocs=null, mpros=null}) => {
    var referrer = title.replaceAll(' ', '').toLowerCase();
    var link = window.location.protocol + '//';
    if (window.location.host.startsWith('lp.'))
        link += window.location.host.slice(3);
    else
        link += window.location.host;
    link = link.replace(':3000', ':5000');
    link += `/signup?referrer=${referrer}`;
    link += `&mlocs=${mlocs}&mpros=${mpros}`;
    if (mlocs == null || mpros == null)
        link = `pricing?referrer=${referrer}`;
    link = "/signup";
    return(
        <figure className={`${avatarInfluencer.picture} reveal`}>
            <a href={link}>
                <img src={image} alt={title} title={title}/>
                <figcaption>{ title }</figcaption>
            </a>
        </figure>
    )
}
