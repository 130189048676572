import button from './button.module.scss';

export const Button = ({children, typeButton, signup_link}) => {

    let compButton;

    switch(typeButton){
        case 'fullWhite': 
        compButton = (
            <button className={`${button.fullWhite} ${button.button}`} >
                { children }
            </button>
        )
        break;
        case 'fullGreen': 
        compButton = (
            <button className={`${button.fullGreen} ${button.button}`} >
                { children }
            </button>
        )
        break;
        case 'ghostGreen': 
        default:
        compButton = (
            <button className={`${button.ghostGreen} ${button.button}`} >
                { children }
            </button>
        )
        break;
    }

    if (signup_link) {
      compButton = (
        <a href="/signup">
          { compButton }
        </a>
      );
    }

    return compButton;
}
