import { Header } from "../header/Header"
import { Footer } from "../footer/Footer"
import { Questions } from "./elements/questions/Questions"
import { AllFeatures } from "./elements/features/AllFeatures"
import { CalcPromotion } from "./elements/calcPromotion/CalcPromotion"
// import { ConfigurationPlan } from "./elements/configurationPlan/ConfigurationPlan"
import { Button } from "../elements/buttons/Button"
import { Modal } from "../elements/modal/Modal"

import price from "./pricing.module.scss"
import { useState, useEffect } from "react"

export const Pricing = () => {
  let [flag, setFlag] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let [numpros, setNumpros] = useState(0)
  let [numlocs, setNumlocs] = useState(2)
  useEffect(() => {
    var numpros = document.getElementById("numpros")
    if (numpros) setNumpros(parseInt(numpros.value))
    var numlocs = document.getElementById("numlocs")
    if (numlocs) setNumlocs(parseInt(numlocs.value))
  }, [flag])

  const changeValue = e => setFlag(!flag)

  var solo = numpros === 1 && numlocs === 1
  if (document.getElementById("team_description"))
    document.getElementById("team_description").hidden =
      !solo
  if (document.getElementById("team_price"))
    document.getElementById("team_price").hidden = solo
  var team = solo ? price.disable : ""
  solo = solo ? "" : price.disable

  var maxShops = 10
  var maxPros = 99

  var link = window.location.href
  link = link.replace(":3000", ":5000")
  var refind = link.indexOf("referrer=")
  if (refind > -1) {
    var referrer = link.slice(refind + 9)
    if (referrer.indexOf("?") > -1)
      referrer = referrer.slice(0, referrer.indexOf("?"))
    link = window.location.protocol + "//"
    if (window.location.host.startsWith("lp."))
      link += window.location.host.slice(3)
    else link += window.location.host
    link = link.replace(":3000", ":5000")
    link += `/signup?referrer=${referrer}`
    link += `&mlocs=${numlocs}&mpros=${numpros}`
  }
  link = "/signup";
  const [modal, setModal] = useState("")
  useEffect(() => {
    if (refind === -1) {
      const btnStart = document.querySelectorAll(`.start`)
      btnStart.forEach(el => {
        el.addEventListener("click", e => {
          e.preventDefault()
          setModal("showModal")
        })
      })
    }
  }, [])

  // pop-menu pricing
  useEffect(() => {
    let menuPricing = document.querySelectorAll(".point")
    let menuWarning =
      document.querySelectorAll(".menu-warning")

    menuPricing.forEach((el, idx) => {
      el.addEventListener("click", e => {
        e.preventDefault()
        menuWarning[idx].style.display = "block"
        setTimeout(
          () => (menuWarning[idx].style.display = "none"),
          4000
        )
      })
    })
  }, [])

  const closeModal = e => setModal("")

  return (
    <main className={price.pricing}>
      <Header />

      <div className="grid2">
        <header className={price.title}>
          <h1>Smash that button below, LET'S GO!</h1>
          {/* <h2>, only pay for what you need!</h2> */}
        </header>

        {/* <section className={price.configuration}>
          <ConfigurationPlan
            change={changeValue}
            image="/icon-shops.svg"
            title="How many shops do you have?"
            max={maxShops}
            input_id="numlocs"
          />
          <ConfigurationPlan
            change={changeValue}
            image="/icon-professionals.svg"
            title="How many professionals will be booking?"
            max={maxPros}
            input_id="numpros"
          />
        </section> */}

        <section className={price.promotion}>
          <div className={price.promotionBox}>
            {/* <CalcPromotion disabled={solo}> */}
            <CalcPromotion>
              <div className={price.headerInfoPromotion}>
                <img
                  src="/icon-plan-solo.svg"
                  alt="Solo"
                  title="Solo"
                />
                <div>
                  <h2>Solo</h2>
                  <h3 className={price.price}>
                    Pricing TBD
                  </h3>
                  {/*<span className={price.period}>
                    /month
                  </span>
                  */}
                  <p className={price.description}>
                    For the independent barber
                  </p>
                </div>
              </div>
              <a className="start" href={link}>
                <Button typeButton="fullGreen">
                  Start Promoting Yourself Now
                </Button>
              </a>
            </CalcPromotion>

            {/* <CalcPromotion disabled={team}> */}
            <CalcPromotion disabled={solo}>
              <div className={price.headerInfoPromotion}>
                <img
                  src="/icon-plan-teams.svg"
                  alt="Shops and Team"
                  title="Shops and Team"
                />
                {/* <h2>Shops and Team</h2> */}
                <div>
                  <h2>Shops and Team</h2>
                  <p
                    id="team_description"
                    className={price.description}
                  >
                    Choose multiple shops and/or
                    professionals to see your team plan.
                  </p>
                  {/* <span id="team_price" hidden>
                    <h3 className={price.price}>
                      <span>$</span>
                      {5 + numlocs * 20 + numpros * 10}
                    </h3>
                    <span className={price.period}>
                      /month
                    </span>
                  </span> */}
                </div>
              </div>
              <a className="start" href={link}>
                <Button typeButton="fullGreen">
                  Coming Soon
                </Button>
              </a>
            </CalcPromotion>
          </div>
        </section>

        <AllFeatures />

        <h2 className={price.priceTitle} id="faqs">
          Why Pie
        </h2>
        <section className={price.questions}>
          <Questions
            toggle="el1"
            title="PROMOTE YOURSELF, NOT US"
          >
            <p>
              Our mission is to empower you to be successful and enable you to
              build and focus on your own brand. We want you to <strong>promote
              yourself, not us.</strong> Pie is the platform that enables you,
              not us.
            </p>
          </Questions>
          <Questions
            toggle="el2"
            title="START USING A BOOKING APP THAT PAYS YOU! "
          >
            <p>
              Ok, you already have a booking app. Cool. But
              you send YOUR clients to someone else's web
              page and it's a booking app that promotes
              other barbers…. Pause…. Think about that for a
              sec… You send YOUR clients to a booking app
              that promotes other barbers, even your
              competitors? Don’t you see the problem here?
              Shouldn’t they be paying You instead?
              <br />
              <br />
              <strong>
                START USING AN APP THAT PAYS YOU
                <sup>&trade;</sup>.
              </strong>
            </p>
          </Questions>
          <Questions
            toggle="el3"
            title="START PROMOTING BRAND"
          >
            <p>
              <strong>
                START PROMOTING YOURSELF <sup>&trade;</sup>
              </strong>
              . We want to help you Build YOUR brand equity!
              You should say{" "}
              <strong>
                “MY BRAND, MY APP <sup>&trade;</sup>”
              </strong>
              .
            </p>
          </Questions>
          <Questions
            toggle="el4"
            title="MORE THAN A BOOKING APP"
          >
            <p>
              Pie is the “engine under the hood” to empower
              YOU! Enough of promoting those greedy booking
              apps out there!{" "}
              <strong>
                {" "}
                PROMOTE YOUR BRAND<sup>&trade;</sup>.
              </strong>
            </p>
          </Questions>
          <Questions
            toggle="el5"
            title="BUILD YOUR OWN BRAND"
          >
            <p>
              When you have a booking app with your logo,
              your own website, you are building your own
              brand. Pie is a platform built to elevate You,
              you are going to say to your clients: “Go to
              my website” or “Download my own app” to book
              an appointment with me.{" "}
              <strong>
                {" "}
                YOUR BRAND, YOUR APP<sup>&trade;</sup>.
              </strong>
            </p>
          </Questions>
          <Questions
            toggle="el6"
            title="PROMOTE YOUR BUSINESS, NOT OURS "
          >
            <p>
              We are the same guys that built Tomb45. You
              supported us, now we want you to support you
              and Pie is the platform to promote yourself.
              <br /> <br />
              We see barbers working hard every day and
              promoting their booking app instead of
              promoting themselves. We built Pie for you to
              promote your business, not ours.
            </p>
          </Questions>
          <Questions
            toggle="el7"
            title="PROMOTE YOURSELF, NOT YOUR BOOKING APP!"
          >
            <p>
              Your clients go to YOUR website or YOUR app,
              you are building your brand.{" "}
              <strong>
                STOP PROMOTING YOUR APP<sup>&trade;</sup>
              </strong>
              . Think about that, every time you send your
              clients to a booking app that is not your own,
              you are actually promoting the booking app,
              not yourself.
            </p>
          </Questions>
          <Questions
            toggle="el8"
            title="BY BARBERS FOR BARBERS"
          >
            <p>
              BY BARBERS FOR BARBERS<sup>&trade;</sup>
            </p>
          </Questions>
        </section>
      </div>

      <Modal
        showClass={modal}
        close={closeModal}
        mlocs={numlocs}
        mpros={numpros}
      />

      <Footer />
      <div className={price.wave}></div>
      <div className={price.wave2}></div>
    </main>
  )
}
